import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';

export function useAdminAccountQuery({ accountId }: { accountId: string }) {
	const userSession = useUserSession();
	return useQuery({
		queryFn: () =>
			userSession.webServiceHelper.callAsync<Api.IAccount>(`admin/account/${encodeURIComponent(accountId)}`, 'GET'),
		queryKey: [`admin/account/<accountId>`, accountId],
	});
}
