import { useInfiniteQuery } from '@tanstack/react-query';
import { IPixabayImageSearchResult } from '../../models/Pixabay';
import { DefaultPixabayApiKey } from './constants';

export const PIXABAY_IMAGE_SEARCH_QUERY_KEY = 'pixabayImageSearchQuery';

export function usePixabayImageSearchQuery({ searchTerm, pageSize = 25 }: { searchTerm: string; pageSize?: number }) {
	return useInfiniteQuery<IPixabayImageSearchResult>({
		enabled: Boolean(searchTerm),
		getNextPageParam: (lastPage: IPixabayImageSearchResult, allPages) => {
			return lastPage.total > allPages.length * pageSize ? allPages.length + 1 : null;
		},
		queryFn: async ({ pageParam }) => {
			const result = await fetch(
				// @ts-ignore
				`https://pixabay.com/api/?key=${encodeURIComponent(DefaultPixabayApiKey)}&q=${encodeURIComponent(
					searchTerm || ''
				)}&image_type=photo&per_page=${pageSize}&page=${pageParam}&type=photo&safesearch=true`,
				{ method: 'GET' }
			);
			return result.json();
		},
		queryKey: [PIXABAY_IMAGE_SEARCH_QUERY_KEY, searchTerm, pageSize],
	});
}
